@use '/src/scss/responsive.scss';
@use '/src/scss/colors.scss';
@use '/src/scss/animations.scss';
@use '/src/scss/styles.scss';

.navMenu {
  display: none;
  &.show {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;

    width: 90vw;
    @include responsive.wsm {
      width: 35vw;
    }
    height: 100vh;

    background-color: colors.$primary;
    background-image: linear-gradient(colors.$secondary, colors.$light);

    animation: slideinRight (animations.$baseDuration - 1.6s) forwards;

    a {
      font-size: 260%;
      @include responsive.wsm {
        font-size: 220%;
      }
      @include responsive.wmd {
        font-size: 200%;
      }
      margin-top: 20px;
      margin-left: 10px;
      padding: 5px;
    }
  }

  @include responsive.wlg {
    display: inline;
  }

  a {
    margin-right: 40px;
    color: white;
    font-weight: bold;
    font-size: 130%;
    text-decoration: none;
    &:hover {
      color: colors.$highlight;
    }
  }
}

.burgerIconButton {
  display: inline;

  background-color: transparent;
  border: none;
  @include styles.cursorPointer;

  &:hover {
    img {
      @include colors.toHighlight;
    }
  }

  @include responsive.wlg {
    display: none;
  }
}

.closeButton {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  align-self: flex-end;
  color: white;
  font-size: 180%;
  margin-top: 10px;
  margin-right: 10px;
  @include styles.cursorPointer;

  @include responsive.wsm {
    font-size: 160%;
  }
  @include responsive.wmd {
    font-size: 140%;
  }
  &:hover {
    color: colors.$highlight;
    img {
      @include colors.toHighlight;
    }
  }

  img {
    margin-left: 5px;
    height: 90%;
    width: 90%;
  }
}

.backdrop {
  display: none;
  &.show {
    animation: fade-in (animations.$baseDuration - 1.6s) forwards;
    display: inline;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 50;
    background-color: rgba(255, 255, 255, 0.6);
  }
}
