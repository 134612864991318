@use "/src/scss/animations";

.bgImg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;

  &.scaleIn {
    animation: scale-in (animations.$baseDuration + 0.5s) forwards;
  }

  @include animations.scale-in__hover;
}
