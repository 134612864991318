@use '/src/scss/colors.scss';
@use '/src/scss/responsive.scss';

.logo {
  font-size: 80px;
  color: colors.$highlight;
  text-shadow: 2px 2px 2px white;
  text-decoration: none;
  margin: 0 0 0 25px;
  height: 100%;
}

.header {
  background-color: colors.$primary;
  background-image: linear-gradient(colors.$primary, colors.$secondary);
  display: flex;

  height: responsive.$smallHeight * 0.16;

  @include responsive.hsm {
    height: 16vh;
  }

  @include responsive.hmd {
    height: 10vh;
  }

  a {
    text-decoration: none;
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
