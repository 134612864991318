@use '/src/scss/responsive.scss';

.address {
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 110%;
    text-decoration: none;

    align-self: center;
    @include responsive.hlg {
      @include responsive.wmd {
        align-self: flex-start;
      }
    }
  }
}
