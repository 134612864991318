@use '/src/scss/responsive.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss";

body {
  margin: 0;
  padding: 0;
  background-color: rgb(246, 246, 246);
  font-size: 14px;
  min-width: 480px;
  font-family: Arial, Helvetica, sans-serif;

  @include responsive.wsm {
    font-size: 16px;
  }
  @include responsive.wmd {
    font-size: 18px;
  }
  @include responsive.wlg {
    font-size: 20px;
  }
}

.noOverflow {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

.strong-text {
  text-transform: uppercase;
  font-weight: bold;
}

.image-gallery-content {
  .image-gallery-slide .image-gallery-image {
    max-height: calc(84vh - 80px); // 80 px for the thumbnail space
    @media screen and (min-height: 768px) {
      max-height: calc(90vh - 80px);
    }
  }
  &.fullscreen {
    .image-gallery-slide .image-gallery-image {
      max-height: calc(100vh - 80px); // 80 px for the thumbnail space
    }
  }
}
