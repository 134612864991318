@use '/src/scss/colors.scss';
@use '/src/scss/variables.scss';
@use '/src/scss/responsive.scss';

.no-form-request {
  width: 100%;
  padding: 40px;
  margin-bottom: variables.$margin;
  text-align: center;
  font-size: 130%;
  background-color: colors.$secondary;
  box-shadow: colors.$boxShadow;
  p {
    margin: 0;
    color: white;
  }

  .email {
    font-size: 150%;
    @include responsive.wsm {
      font-size: 220%;
    }
    @include responsive.wlg {
      font-size: 240%;
    }
    margin-top: 20px;
  }

  a {
    text-decoration: none;
    &:hover {
      p {
        color: colors.$highlight;
      }
    }
  }
}
