@use '/src/scss/colors.scss';
@use '/src/scss/responsive.scss';

.card {
  background-color: white;
  box-shadow: colors.$boxShadow;
  display: flex;
  width: 100%;
  height: 100%;
}

.cardContainer {
  padding: 10px;
  box-sizing: border-box;
  min-height: 350px;

  @include responsive.wlg {
    width: 50%;
  }

  height: 84vh;
  padding-top: 20px;
  padding-bottom: 20px;

  @include responsive.hsm {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @include responsive.hmd {
    height: 45vh;
    padding: 10px;
  }
}

.isLink {
  &:hover {
    cursor: pointer;
  }
}
