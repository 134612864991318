@use '/src/scss/animations.scss';
@use '/src/scss/responsive.scss';

.card-layout {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @include responsive.wlg {
    flex-direction: row;
    flex-wrap: wrap;
  }

  & > div {
    opacity: 0;
    transform: translateY(30%);
    animation: fade-slide-in animations.$baseDuration forwards;

    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        animation-delay: ($i - 1) * animations.$baseDelay;
      }
    }
  }
}
