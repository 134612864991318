@use '/src/scss/responsive.scss';

.viewport {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;

  min-height: responsive.$smallHeight * 0.84;

  @include responsive.hsm {
    min-height: 84vh;
  }

  @include responsive.hmd {
    min-height: 90vh;
  }
}
