@use '/src/scss/colors.scss';

.request-instructions {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;

  img {
    height: 70%;
    max-width: 95%;
  }
  p {
    text-align: center;
  }

  .numbering {
    border-radius: 50%;
    box-shadow: 0 0 2px 3px colors.$light, colors.$boxShadow;
    background-color: colors.$light;
    color: white;
    width: 50px;
    height: 50px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 12px;
    top: 12px;
  }
}
