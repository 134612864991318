@use 'sass:color';

$primary: rgb(21, 23, 53);
$secondary: color.adjust($primary, $lightness: 15%);
$light: color.adjust($primary, $lightness: 35%);
$highlight: #eb192e;

$boxShadow: 2px 3px 5px 5px rgba(0, 0, 0, 0.1);

@mixin toHighlight {
  filter: brightness(0) saturate(100%) invert(27%) sepia(94%) saturate(5363%)
    hue-rotate(343deg) brightness(91%) contrast(102%);
}
