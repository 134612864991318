@use '/src/scss/colors.scss';
@use '/src/scss/animations.scss';
@use '/src/scss/responsive.scss';

.thumbnail {
  width: 99%;
  height: 99%;
  display: flex;
  margin: auto;
  position: relative;

  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.2) 25%,
      rgba(0, 0, 0, 0.2) 75%,
      rgba(0, 0, 0, 0.5)
    );
    position: absolute;
  }

  p {
    color: white;
    margin: auto;
    font-size: 300%;
    z-index: 1;
  }

  .overlay,
  p {
    @include responsive.wlg {
      @include animations.fade-in__hover;
    }
  }
}
