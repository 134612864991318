@use '/src/scss/animations.scss';

.white-overlay {
  opacity: 0;
  animation: fade-in animations.$baseDuration forwards;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.25);
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.15) 25%,
    rgba(255, 255, 255, 0.15) 75%,
    rgba(255, 255, 255, 0.7)
  );
  position: absolute;
}
