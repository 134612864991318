$baseDelay: 0.5s;
$baseDuration: 2s;

@keyframes scale-in {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-slide-in {
  0% {
    opacity: 0;
    transform: translateY(30%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes slideinRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@mixin scale-in__hover {
  transition: transform ($baseDuration - 0.8s);
  &.isHovered {
    transform: scale(1.2);
  }
}

@mixin fade-in__hover {
  opacity: 0;
  transition: opacity $baseDuration;
  &.isHovered {
    opacity: 1;
  }
}
