@use '/src/scss/responsive.scss';

.are-you-interested {
  height: 100vh;
  min-height: 260px;
  position: relative;
  display: flex;
  order: 1;
  @include responsive.wmd {
    @include responsive.hmd {
      height: 30vh;
      order: 0;
    }
    @include responsive.hlg {
      height: 40vh;
    }
  }
}
