@use '/src/scss/responsive.scss';

.personal-info {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  flex: 1;
  justify-content: space-evenly;

  @include responsive.hlg {
    @include responsive.wmd {
      flex: initial;
      align-items: flex-start;
      align-self: stretch;
    }
  }

  div {
    display: flex;
    align-items: center;
    width: fit-content;

    p {
      display: flex;
      font-weight: bold;
      font-size: 140%;
      text-decoration: none;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
  }
}
