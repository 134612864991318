@use '/src/scss/animations.scss';
@use '/src/scss/responsive.scss';

.text-box {
  opacity: 0;
  animation: fade-in (animations.$baseDuration - 0.2s) forwards;
  margin: auto;
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.82);

  p {
    //   @include responsive.sm {
    //     font-size: 140%;
    //   }
    //   @include responsive.md {
    //     font-size: 180%;
    //   }
    //   @include responsive.lg {
    font-size: 220%;
    // }
    color: white;
  }

  & > * {
    margin: 15px;
  }
}
