@use '/src/scss/colors.scss';

.backToGallery {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  color: colors.$primary;
  &:hover {
    cursor: pointer;
    color: colors.$highlight;
  }
  z-index: 10;

  p {
    font-size: 30px;
    font-weight: bold;
    margin: 0;

    text-align: center;
  }
}
