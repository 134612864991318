@use "/src/scss/colors.scss";
@use '/src/scss/responsive.scss';
@use '/src/scss/styles.scss';

.footer {
  min-height: 70px;
  background-color: colors.$primary;
  background-image: linear-gradient(colors.$secondary, colors.$primary);
}

.contacts-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  @include responsive.wlg {
    justify-content: space-between;
  }

  div {
    display: flex;
    align-items: center;
    @include styles.cursorPointer;
    &:hover {
      p {
        color: colors.$highlight;
      }
    }
    p {
      color: white;
      display: flex;
      font-weight: bold;
      font-size: 25px;
    }
  }
  a {
    text-decoration: none;
  }
}
