@use '/src/scss/animations.scss';

.scroll-down-hint {
  position: absolute;
  width: 100%;
  bottom: 50px;

  opacity: 0;

  animation: fade-in (animations.$baseDuration - 0.3s) forwards;
  animation-delay: animations.$baseDelay * 4;

  & > div {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 25px;
    height: 25px;
    border-right: solid 2px;
    border-bottom: solid 2px;

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        border-color: rgba(0, 0, 0, (1 - ($i * 0.2)));
        transform: translateY(($i - 1) * -8px) rotate(45deg);
      }
    }
  }
}
