@use '/src/scss/animations.scss';
@use '/src/scss/responsive.scss';

.contact-us-layout {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: fade-in animations.$baseDuration forwards;

  min-height: 84vh;
  height: auto;

  @include responsive.hmd {
    min-height: 90vh;
  }

  @include responsive.hmd {
    @include responsive.wmd {
      justify-content: space-around;
      height: 100%;
      min-height: auto;
      flex-direction: row;
    }
  }
}
