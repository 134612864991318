@use '/src/scss/responsive.scss';

.page-width {
  max-width: 95%;

  @include responsive.wmd {
    max-width: 768px;
  }
  @include responsive.wlg {
    max-width: 1024px;
  }

  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
}
