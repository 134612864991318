@use '/src/scss/colors.scss';
@use 'sass:color';
@use '/src/scss/responsive.scss';

.contact-us {
  color: #fff;
  background-color: colors.$highlight;
  border-color: colors.$highlight;
  font-size: 100%;
  padding: 12px 20px;
  text-decoration: none;
  border-radius: 6px;
  width: 100%;

  @include responsive.wmd {
    width: fit-content;
  }

  &:hover {
    color: #fff;
    background-color: color.adjust(colors.$highlight, $lightness: -5%);
    border-color: color.adjust(colors.$highlight, $lightness: -5%);
  }
}
